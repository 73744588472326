import React, { useState, useEffect } from 'react';
import { useAuthenticator, Heading } from '@aws-amplify/ui-react';
import { API } from 'aws-amplify';
import { getUser } from '../graphql/queries';
import { LinearProgress, Typography } from '@mui/material'

const User = () => {
    const { user: { attributes: { sub } } } = useAuthenticator((context) => [context.route]);

    const [user, setUser] = useState();


    useEffect(() => {
        async function fetchUser() {
            const apiData = await API.graphql({ query: getUser, variables: { sub } });
            setUser({ ...apiData.data.getUser })
        }
        fetchUser();
    }, [sub]);

    return (
        <div>
            <Typography variant='h4'>User Details</Typography>
            {!user ? <LinearProgress /> : (
                <div>
                    <p>{user.email}</p>
                    <p>{user.region}</p>
                    <p>{user.userPoolId}</p>
                    <p>{user.sub}</p>
                </div>
            )}
        </div>
    )
}

export default User