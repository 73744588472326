
export const breakdownPoints = points => {
    if (!Array.isArray(points) || points.length < 1) {
        return {
            times: [],
            temps: [],
            humidities: [],
            lightStates: [],
        }
    }
    let latest;
    const times = [];
    const temps = [];
    const humidities = [];
    const lightStates = [];
    points.forEach(point => {
        if (!latest || latest.time < point.time) {
            latest = { ...point }
        }
        const { time, temp, humidity, lightState } = point;
        times.push(time)
        if (temp) {
            temps.push(temp)
        }
        if (humidity) {
            humidities.push(humidity)
        }
        if (lightState) {
            lightStates.push(lightState)
        }
    })
    return {
        times,
        temps,
        humidities,
        lightStates,
        latest
    }
}

export const getAverage = numbers => {
    if (!Array.isArray(numbers) || numbers.length < 1) {
        throw new Error(`Bad array passed to getAverage ${numbers}`)
    }
    let total = 0;
    numbers.forEach(x => total += x)
    return total / numbers.length;
}

export const celciusToFahrenheit = x => {
    if (typeof x !== 'number') {
        throw new Error(`Invalid temperature supplied to celciusToFahrenheit: ${x}`)
    }
    return (((x * 9) / 5) + 32).toFixed(2)
}

export const fahrenheitToCelcius = x => {
    if (typeof x !== 'number') {
        throw new Error(`Invalid temperature supplied to fahrenheitToCelcius: ${x}`)
    }
    return (((x - 32) * 5) / 9).toFixed(2)
}

export const getTempIndicators = (sensor, points, breakdown) => {
    if (!sensor.hasTemp) return null;
    const title = "Temperature";
    if (!Array.isArray(points) || points.length < 1) {
        return {
            title,
            noData: true
        }
    }
    return {
        title,
        max: Math.max(...breakdown.temps),
        average: getAverage(breakdown.temps),
        min: Math.min(...breakdown.temps),
        latestValue: `${celciusToFahrenheit(breakdown.latest.temp)} \u00b0F`,
        latestTime: breakdown.latest.time / 1000
    }
}

export const getHumidityIndicators = (sensor, points, breakdown) => {
    if (!sensor.hasHumidity) return null;
    const title = "Humidity"
    if (!Array.isArray(points) || points.length < 1) {
        return {
            title,
            noData: true
        }
    }
    return {
        title,
        max: Math.max(...breakdown.humidities),
        average: getAverage(breakdown.humidities),
        min: Math.min(...breakdown.humidities),
        latestValue: `${breakdown.latest.humidity} %`,
        latestTime: breakdown.latest.time / 1000
    }
}

export const getLightIndicators = (sensor, points, breakdown) => {
    if (!sensor.hasLightState) return null;
    if (!Array.isArray(points) || points.length < 1) {
        return {
            title: "Light State",
            noData: true
        }
    }
    return {
        title: `Light State`,
        latestValue: breakdown.latest.lightState,
        latestTime: breakdown.latest.time / 1000
    }

}

export const sortPoints = points => {
    return points.sort((a, b) => {
        if (a.time < b.time) {
            return 1;
        }
        if (a.time > b.time) {
            return -1;
        }
        return 0;
    })
}