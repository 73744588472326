import { useAuthenticator } from '@aws-amplify/ui-react';
import { Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Alarms = () => {

    return (
        <Grid container justifyContent="space-between">
            <Grid item>
                <Typography variant="h4">This will display the sensor's alarms</Typography>
            </Grid>
        </Grid>
    );
}

export default Alarms