//App.js
import { Authenticator } from '@aws-amplify/ui-react';
import { RequireAuth } from './components/RequireAuth.js';
import { Login } from './components/Login.js';
import CreateSensor from './components/forms/CreateSensor';
import Landing from './components/Landing'
import Dashboard from './components/Dashboard.js';
import Layout from './components/Layout';
import ListSensors from './components/ListSensors'
import User from './components/User';
import Sensor from './components/sensor/Sensor';
import Alarms from './components/Alarms'
import CreateTrigger from './components/forms/CreateTrigger'

import { BrowserRouter, Routes, Route } from 'react-router-dom';

import './App.css';

function MyRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Landing />} />
          <Route
            path="/dashboard"
            element={
              <RequireAuth>
                <Dashboard />
              </RequireAuth>
            }
          />
          <Route
            path="/sensors/add"
            element={
              <RequireAuth>
                <CreateSensor />
              </RequireAuth>
            }
          />
          <Route
            path="/sensors"
            element={
              <RequireAuth>
                <ListSensors />
              </RequireAuth>
            }
          />
          <Route
            path="/user"
            element={
              <RequireAuth>
                <User />
              </RequireAuth>
            }
          />
          <Route
            path="/sensors/:sensorId"
            element={
              <RequireAuth>
                <Sensor />
              </RequireAuth>
            }
          />
          <Route
            path="/sensors/:sensorId/alarms/create"
            element={
              <RequireAuth>
                <CreateTrigger />
              </RequireAuth>
            }
          />
          <Route
            path="/sensors/:sensorId/alarms"
            element={
              <RequireAuth>
                <Alarms />
              </RequireAuth>
            }
          />
          <Route path="/login" element={<Login />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

function App() {
  return (
    <Authenticator.Provider>
      <MyRoutes />
    </Authenticator.Provider>
  );
}

export default App;