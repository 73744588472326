import moment from 'moment';
import React, { useState, useEffect } from 'react';
import {
    Label,
    LineChart,
    Line,
    CartesianGrid,
    XAxis,
    YAxis,
    Tooltip,
    ReferenceArea,
    ResponsiveContainer,
} from 'recharts';
import { Button, Grid } from '@mui/material'
import { useTheme } from '@mui/material/styles';
import TimeAxisTick from './TimeAxisTick';
import { celciusToFahrenheit } from './utils';


const getAxisYDomain = (refData, ref, offset) => {

    let [bottom, top] = [refData[0][ref], refData[0][ref]];
    refData.forEach((d) => {
        if (d[ref] > top) top = d[ref];
        if (d[ref] < bottom) bottom = d[ref];
    });

    return [(bottom | 0) - offset, (top | 0) + offset];
};

// Should be passed points from props which are always in C
// Only does anything if F is selected as unit
const applyUnitToPoints = (unit, points) => {
    if (['C', 'F'].includes(unit) && Array.isArray(points)) {
        if (unit === 'C') return [...points]
        return points.map(x => ({ ...x, temp: celciusToFahrenheit(x.temp) }))
    }
    return [];
}
const TemperatureChart = props => {
    const { points: _points } = props;

    const [unit, setUnit] = useState('F')
    const [points, setPoints] = useState(applyUnitToPoints(unit, _points))
    // Switch to F
    const initialState = {
        data: [...points],
        left: 'dataMin',
        right: 'dataMax',
        refAreaLeft: '',
        refAreaRight: '',
        top: 120,
        bottom: 0,
        animation: true,
    }
    const [zoomState, setZoomState] = useState({ ...initialState })
    const [isZoomed, setIsZoomed] = useState(false)

    // INITIALIZE & UPDATE
    useEffect(() => {
        setZoomState({ ...initialState, data: [...points] })
    }, [points])

    // Apply desired unit to temp
    useEffect(() => {
        setPoints(applyUnitToPoints(unit, _points))
    }, [unit])


    const zoom = () => {
        let { refAreaLeft, refAreaRight } = zoomState;
        if (refAreaLeft === refAreaRight || refAreaRight === '') {
            setZoomState({
                ...zoomState,
                refAreaLeft: '',
                refAreaRight: '',
            })
            return;
        }

        const refData = [...points].filter(x => x.time >= refAreaLeft && x.time <= refAreaRight)

        // xAxis domain
        if (refAreaLeft > refAreaRight) [refAreaLeft, refAreaRight] = [refAreaRight, refAreaLeft];

        // yAxis domain
        const [bottom, top] = getAxisYDomain(refData, 'temp', 10);

        setZoomState({
            refAreaLeft: '',
            refAreaRight: '',
            data: [...refData],
            left: refAreaLeft,
            right: refAreaRight,
            bottom,
            top,
        })
        setIsZoomed(true)
    }

    const zoomOut = () => {
        setZoomState({ ...initialState })
        setIsZoomed(false)
    }

    const { data, left, right, refAreaLeft, refAreaRight, top, bottom } = zoomState

    const theme = useTheme()
    const styles = {
        chartContainer: {
            paddingRight: theme.spacing(3),
        },
        buttonContainer: {
            height: 40
        }
    }

    return (
        <Grid container>
            <Grid item xs={12} sx={styles.chartContainer}>
                <ResponsiveContainer width="99%" minHeight={450}>
                    <LineChart
                        height={300}
                        data={data}
                        onMouseDown={(e) => setZoomState({ ...zoomState, refAreaLeft: e.activeLabel })}
                        onMouseMove={(e) => zoomState.refAreaLeft && setZoomState({ ...zoomState, refAreaRight: e.activeLabel })}
                        // eslint-disable-next-line react/jsx-no-bind
                        onMouseUp={zoom}
                    >
                        <CartesianGrid />
                        <XAxis
                            dataKey="time"
                            scale="time"
                            domain={[left, right]}
                            type="number"
                            tickFormatter={x => moment.unix(x / 1000).format("h:mm a")}
                            interval="preserveStartEnd"
                            tickCount={3}

                        />
                        <YAxis allowDataOverflow dataKey="temp" domain={[bottom, top]} type="number" yAxisId="1" />
                        <Tooltip
                            labelFormatter={(x) => moment.unix(x / 1000).format("M/D h:mm a")}
                        />
                        <Line
                            yAxisId="1"
                            dataKey="temp"
                            stroke="#8884d8"
                            animationDuration={300}
                            dot={false}
                            type="number"
                        />

                        {refAreaLeft && refAreaRight ? (
                            <ReferenceArea yAxisId="1" x1={refAreaLeft} x2={refAreaRight} strokeOpacity={0.3} />
                        ) : null}
                    </LineChart>
                </ResponsiveContainer>
            </Grid>
            <Grid item xs style={styles.buttonContainer}>
                {isZoomed && <Button onClick={zoomOut}> Zoom Out </Button>}
            </Grid>
        </Grid>
    );

}

export default TemperatureChart;