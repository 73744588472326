import { useAuthenticator } from '@aws-amplify/ui-react';
import { Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Dashboard = () => {
    const { route } = useAuthenticator((context) => [context.route]);

    const navigate = useNavigate();
    const authenticated = route === 'authenticated';
    if (!authenticated) {
        navigate('/login')
    }

    return (
        <Grid container justifyContent="space-between">
            <Grid item>
                <Typography variant="h4">This will be a dashboard eventually</Typography>
            </Grid>
        </Grid>
    );
}

export default Dashboard