import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const steps = ['Triggers', 'Actions', 'Review'];

export default function CreatePatameterStepper({ activeStep }) {


    return (
        <Stepper activeStep={activeStep} >
            {steps.map((label, index) => {
                return (
                    <Step key={label}>
                        <StepLabel >{label}</StepLabel>
                    </Step>
                );
            })}
        </Stepper>
    );
}