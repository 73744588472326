import React from 'react'
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Breadcrumbs, Link, Typography } from '@mui/material'

const Crumbs = ({ pathname: pathnameProp }) => {
    const { pathname: pathnameLocation } = useLocation()
    const navigate = useNavigate()
    const getTitle = (path) => {
        switch (path) {
            case 'dashboard': return "Dashboard";
            case 'sensors': return "Sensors";
            case 'add': return "Add";
            case 'user': return "Profile";
            case 'login': return "Login";
            default: return path
        }
    }
    // Path name passed to component overrides actual location
    const pathname = pathnameProp || pathnameLocation
    const split = ['', ...pathname.split('/').filter(x => !!x)]
    const lastIndex = split.length - 1
    const dontShow = pathname.includes('login') || pathname.includes('public') || split.length < 1
    return (
        <Breadcrumbs aria-label="breadcrumb">
            {!dontShow && split.map((path, i) => {
                if (i === lastIndex) {
                    return <Typography color="text.primary" key={i}>{getTitle(path)}</Typography>
                }
                return (
                    <Typography
                        sx={{
                            "&:hover": {
                                textDecoration: 'underline',
                                cursor: "pointer",
                                color: 'inherit'
                            },
                        }}
                        onClick={() => navigate(pathname.split(path)[0] + path)}
                        key={i}
                    >
                        {getTitle(path)}
                    </Typography>
                )
            })}
        </Breadcrumbs>
    );
}

export default Crumbs;