import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  useMediaQuery,
  Drawer,
  AppBar,
  Toolbar,
  List,
  Typography,
  Divider,
  IconButton,
  Badge,
  ClickAwayListener,
  ListItem,
  ListItemText,
  ListItemIcon,
  Grid
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import SensorsIcon from '@mui/icons-material/Sensors';
import PersonIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout';
import LoginIcon from '@mui/icons-material/Login';
import HomeIcon from '@mui/icons-material/Home';
import { useNavigate, Outlet, useLocation } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';
import Breadcrumbs from './Breadcrumbs';

const Layout = props => {
  const { route, signOut } = useAuthenticator((context) => [
    context.route,
    context.signOut,
  ]);
  const theme = useTheme()

  const largeScreen = useMediaQuery(theme.breakpoints.up('lg'));
  const [open, setOpen] = useState(largeScreen);
  const [pageDrawerOptions, setPageDrawerOptions] = useState([]);
  const [pathname, setPathname] = useState();

  const navigate = useNavigate();



  function logout() {
    signOut();
    handleDrawerClose()
    navigate('/');
  }

  const handleDrawerOpen = () => {
    setOpen(true)
  };

  const handleDrawerClose = () => {
    setOpen(false)
  };

  useEffect(() => {
    setOpen(largeScreen)
  }, [largeScreen])

  const styles = {
    spacer: {
      height: theme.spacing(2)
    },
  };

  const handleNavigate = path => {
    if (!largeScreen) {
      handleDrawerClose()
    }
    navigate(path)
  }
  const renderDrawerOption = ({ onClick, icon, label }, i) => {
    return (
      <ListItem button onClick={() => { if (!largeScreen) handleDrawerClose(); onClick() }} key={`customDrawerOption_${i}`}>
        <ListItemIcon>
          {icon}
        </ListItemIcon>
        <ListItemText primary={label} />
      </ListItem>
    )
  }

  const authenticated = route === 'authenticated';
  const showDrawerOptions = pageDrawerOptions.length > 0 && open && authenticated;
  return (
    <div>

      {!open && authenticated && (
        <IconButton onClick={handleDrawerOpen}>
          <MenuIcon />
        </IconButton>
      )}

      <Grid container direction="row">
        <Grid item lg={authenticated && (largeScreen || open) ? 2 : 0}>
          <Drawer
            sx={{
              width: 220,
              flexShrink: 0,
              '& .MuiDrawer-paper': {
                width: 220,
                boxSizing: 'border-box',
              },
            }}
            open={authenticated && (largeScreen || open)}
            variant={largeScreen ? "persistent" : "temporary"}
          >
            <div>
              {!largeScreen && (
                <div>
                  <IconButton onClick={handleDrawerClose}>
                    <ChevronLeftIcon />
                  </IconButton>
                </div>
              )}

              {/* Authenticated Standard Menu Items */}
              {route === "authenticated" && (
                <List sx={{ marginTop: 'auto' }}>
                  <ListItem button onClick={() => handleNavigate('/dashboard')}>
                    <ListItemIcon>
                      <HomeIcon />
                    </ListItemIcon>
                    <ListItemText primary="Dashboard" />
                  </ListItem>
                  <ListItem button onClick={() => handleNavigate('sensors')}>
                    <ListItemIcon>
                      <SensorsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Sensors" />
                  </ListItem>
                  <ListItem button onClick={() => handleNavigate('user')}>
                    <ListItemIcon>
                      <PersonIcon />
                    </ListItemIcon>
                    <ListItemText primary="Profile" />
                  </ListItem>
                  <ListItem button onClick={logout}>
                    <ListItemIcon>
                      <LogoutIcon />
                    </ListItemIcon>
                    <ListItemText primary="Logout" />
                  </ListItem>
                </List>
              )}
              {showDrawerOptions && (<Divider />)}
              {showDrawerOptions && (
                <List>
                  {pageDrawerOptions.map(renderDrawerOption)}
                </List>
              )}
            </div>
          </Drawer>
        </Grid>
        <Grid item xs={12} lg={9} sx={{ padding: theme.spacing(3) }}>
          <Breadcrumbs pathname={pathname} />
          <div style={styles.spacer}></div>
          <Outlet context={[setPageDrawerOptions, setPathname]} />
        </Grid>
      </Grid>
    </div >
  );
}

export default Layout;