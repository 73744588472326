import React, { useState, useEffect } from 'react'
import { Grid, LinearProgress, Card, CardContent } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import moment from 'moment';
import { breakdownPoints, getTempIndicators, getHumidityIndicators, getLightIndicators } from './utils';
import AttributeIndicators from './AttributeIndicators'
import TemperatureChart from './TemperatureChart'

const AttributeContainer = props => {
    const { points, sensor, timeRange, selectedAttribute } = props;

    // STATE
    const [tick, setTick] = useState(true)
    const [timeSinceLatestPoint, settimeSinceLatestPoint] = useState("");
    const [breakdown, setBreakdown] = useState()
    const [tempIndicators, setTempIndicators] = useState();
    const [humidityIndicators, setHumidityIndicators] = useState()
    const [lightIndicators, setLightIndicators] = useState();
    const [initializing, setInitailizing] = useState(true)

    // INITIALIZE & UPDATE
    useEffect(() => {
        const breakdown = breakdownPoints(points)
        setBreakdown(breakdown)
        setTempIndicators(getTempIndicators(sensor, points, breakdown));
        setHumidityIndicators(getHumidityIndicators(sensor, points, breakdown))
        setLightIndicators(getLightIndicators(sensor, points, breakdown));
        settimeSinceLatestPoint(moment.unix(breakdown.latest?.time / 1000).fromNow())
        setInitailizing(false)
    }, [points, sensor])

    useEffect(() => {
        const timer = setTimeout(() => {
            setTick(!tick);
            breakdown?.latest && settimeSinceLatestPoint(moment.unix(breakdown.latest.time / 1000).fromNow())
        }, 1000)
        return () => clearTimeout(timer)
    }, [breakdown, tick])

    // STYLE
    const theme = useTheme()
    const styles = {
        root: {
            paddingBottom: theme.spacing()
        },
        card: {
            transition: "0.3s",
            boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
            "&:hover": {
                boxShadow: "0 24px 70px -12.125px rgba(0,0,0,0.3)"
            }
        },
        cardContent: {
            textAlign: 'center',
            padding: theme.spacing(3),
        },
        cardTitle: { fontSize: 14 },
        cardSubText: { mb: 1.5 },
    }

    if (initializing) return <LinearProgress />

    return (
        <Grid container spacing={2} justifyContent="space-around">
            {tempIndicators && selectedAttribute === 'temp' && (
                <Grid item xs >
                    <Card sx={styles.card}>
                        <CardContent sx={styles.cardContent}>
                            <AttributeIndicators
                                indicators={tempIndicators}
                                timeSinceLatestPoint={timeSinceLatestPoint}
                            />
                            <TemperatureChart
                                sensor={sensor}
                                points={points}
                                timeRange={timeRange}
                                breakdown={breakdown}
                            />
                        </CardContent>
                    </Card>
                </Grid>
            )}
            {humidityIndicators && selectedAttribute === 'humidity' && (
                <Grid item xs >
                    <Card sx={styles.card}>
                        <CardContent sx={styles.cardContent}>
                            <AttributeIndicators
                                indicators={humidityIndicators}
                                timeSinceLatestPoint={timeSinceLatestPoint}
                            />
                            {/* <TemperatureChart
                                sensor={sensor}
                                points={points}
                                timeRange={timeRange}
                                breakdown={breakdown}
                            /> */}
                        </CardContent>
                    </Card>
                </Grid>
            )}
            {lightIndicators && selectedAttribute === 'lightState' && (
                <Grid item xs >
                    <Card sx={styles.card}>
                        <CardContent sx={styles.cardContent}>
                            <AttributeIndicators
                                indicators={lightIndicators}
                                timeSinceLatestPoint={timeSinceLatestPoint}
                            />
                            {/* <TemperatureChart
                                sensor={sensor}
                                points={points}
                                timeRange={timeRange}
                                breakdown={breakdown}
                            /> */}
                        </CardContent>
                    </Card>
                </Grid>
            )}
        </Grid>
    )
}

export default AttributeContainer;