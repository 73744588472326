import React, { useState, useEffect } from 'react';
import { createTrigger as createTriggerMutation } from '../../graphql/mutations';
import { API } from 'aws-amplify';
import { getSensor } from '../../graphql/queries';
import { useParams, useLocation, useOutletContext } from "react-router-dom";
import { useTheme } from '@mui/material/styles';
import {
    Grid,
    Box,
    TextField,
    Radio,
    RadioGroup,
    FormControl,
    FormControlLabel,
    FormLabel,
    FormGroup,
    Checkbox,
    FormHelperText,
    Button,
    Typography,
    LinearProgress,
} from '@mui/material';
import CreateTriggerStepper from './display/CreateTriggerStepper';
import useMediaQuery from '@mui/material/useMediaQuery';


const blankError = {
    formError: '',
    value: ''
}

const CreateAlarm = () => {
    // State
    const [sensor, setSensor] = useState()
    const [attribute, setAttribute] = useState('');
    const [unit, setAttributeUnit] = useState('');
    const [isRange, setIsRange] = useState(false)
    const [rangeStart, setRangeStart] = useState()
    const [rangeEnd, setRangeEnd] = useState()

    const [value, setAttributeValue] = useState('');
    const [timeStart, setTimeStart] = useState()
    const [timeEnd, setTimeEnd] = useState()
    const [comparitor, setComparitor] = useState('');
    const [loading, setLoading] = useState(false)
    const [activeStep, setActiveStep] = useState(0)
    const [error, setError] = useState(blankError)

    // Router hooks
    const [setPageDrawerOptions, setPathname] = useOutletContext();
    const { sensorId } = useParams();
    const { pathname } = useLocation()

    // Styling hooks
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    // Initialization and lisnters

    // Get sensor 
    useEffect(() => {
        async function fetchSensor() {
            const apiData = await API.graphql({ query: getSensor, variables: { id: sensorId } });
            setSensor(apiData.data.getSensor);
        }
        fetchSensor()
    }, [sensorId]);
    // Reset loading state
    useEffect(() => {
        if (sensor && loading) {
            setLoading(false)
        }
    }, [sensor, loading]);
    // Initialize breadcrumbs with sensor name
    useEffect(() => {
        // Use sensor details to initialize better breadcrumbs
        if (sensor?.name && pathname.includes(sensorId)) {
            setPathname(pathname.replace(sensorId, sensor.name))
        }

    }, [sensor]);
    // Release pathname override on unrender
    useEffect(() => () => {
        setPathname()
    }, []);
    // Initialize sensor-approptiate initial form values
    useEffect(() => {
        // Use sensor details to initialize better breadcrumbs
        if (sensor) {
            setAttributeDefaults(sensor)
        }

    }, [sensor]);
    // validate on value change
    useEffect(() => {
        validate()
    }, [value, unit, attribute]);


    // Helper functions
    const getAttributeTitle = x => {
        switch (x) {
            case 'temp': return "Temperature";
            case 'humidity': return "Humidity";
            case 'lightState': return "Light State";
            default: return x
        }
    }

    const setAttributes = props => {
        setAttribute(props?.att)
        setAttributeUnit(props?.unit)
        setAttributeValue(props?.value)
        setComparitor(props?.comparitor)

    }

    const getMinValue = () => {
        if (attribute === 'temp') {
            if (unit === 'C') {
                return -273
            }
            return -450
        }
        return 0;
    }

    const getMaxValue = () => {
        if (attribute === 'temp') {
            if (unit === 'C') {
                return 250
            }
            return 1000
        }
        return 100;
    }

    const setAttributeDefaults = (sensor, att) => {
        if (!sensor) {
            setAttribute('')
            setAttributeUnit('')
            setAttributeValue('')
            setComparitor('')
        }
        const map = {
            temp: {
                att: 'temp',
                unit: 'C',
                comparitor: 'gt',
                value: '',
            },
            humidity: {
                att: 'humidity',
                unit: '%',
                comparitor: 'gt',
                value: '',
            },
            lightState: {
                att: 'lightState',
                comparitor: 'e',
                value: 'ON'
            }
        }

        if (att) {
            // attribute was clicked
            if (!map[att]) {
                setError({
                    formError: `Sensor does not record ${getAttributeTitle(att)}`
                })
            }
            setAttributes(map[att])
        } else if (sensor.hasTemp) {
            // Cond'l initialization
            setAttributes(map['temp'])
        } else if (sensor.hasHumidity) {
            // Cond'l initialization
            setAttributes(map['humidity'])

        } else if (sensor.hasLightState) {
            // Cond'l initialization
            setAttributes(map['lightState'])

        } else {
            setError({
                formError: "Sensor collects no alarmable data."
            })
        }
    }

    const validate = () => {
        setError(blankError)
        if (attribute === 'temp' && value) {
            if (value < getMinValue()) {
                setError({ ...error, value: `Temperature must be greater than ${getMinValue()}\u00b0${unit}` })
                return false
            }
            if (value > getMaxValue()) {
                setError({ ...error, value: `Temperature must be less than ${getMaxValue()}\u00b0${unit}` })
                return false;
            }
        }

        if (attribute === 'humidity' && value) {
            if (value < getMinValue()) {
                setError({ ...error, value: `Humidity must be greater than ${getMinValue()}${unit}` })
                return false
            }
            if (value > getMaxValue()) {
                setError({ ...error, value: `Humidity must be less than ${getMaxValue()}${unit}` })
                return false;
            }
        }
        return false
    }

    const resetState = () => {
        setAttribute('')
        setAttributeUnit('')
        setAttributeValue('')
        setComparitor('')
        setLoading(false)
    }

    // Input Handlers
    const handleSubmit = async () => {
        if (validate()) {
            setLoading(true)
            try {
                await API.graphql({
                    query: createTriggerMutation,
                    variables: {
                        input: {
                            attribute,
                            unit,
                            isRange,
                            rangeStart,
                            rangeEnd,
                            value,
                            timeStart,
                            timeEnd,
                            comparitor,
                            actions: [],
                            sensorId: sensor?.id
                        }
                    }
                });
                resetState()
            } catch (e) {
                console.error("Error submitting", e);
            }
        }
    }

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };



    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { m: 1, width: '25ch' },
                width: '100%'
            }}
            autoComplete="off"
        >
            <Grid container direction="column" spacing={4}>

                <Grid item>
                    <Typography variant='h4' sx={{ marginBottom: theme.spacing(2) }}>Add Alarms to Sensor {sensor?.name}</Typography>
                </Grid>

                <Grid item >
                    <CreateTriggerStepper activeStep={activeStep} setActiveStep={setActiveStep} />
                </Grid>

                {loading ? <LinearProgress /> : (
                    // FORM
                    <Grid item container >
                        {/* Attribute */}
                        <Grid item>
                            <FormControl
                                component="fieldset"
                                sx={{ m: 1 }}
                            >
                                <FormLabel id="type-select">Attribute</FormLabel>
                                <RadioGroup
                                    aria-labelledby="type-select"
                                    value={attribute}
                                    name="type-select"
                                    onChange={e => setAttributeDefaults(sensor, e.target.value)}
                                >
                                    <FormControlLabel value="temp" control={<Radio />} label="Temperature" />
                                    <FormControlLabel value="humidity" control={<Radio />} label="Humidity" />
                                    <FormControlLabel value="lightState" control={<Radio />} label="Light State" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>

                        {attribute !== 'lightState' ? (
                            // Numeric Values
                            <Grid item>
                                <TextField
                                    type="number"
                                    value={value}
                                    onChange={e => setAttributeValue(e.target.value)}
                                    label={getAttributeTitle(attribute)}
                                    error={!!error.value}
                                    inputProps={{
                                        inputMode: 'numeric',
                                        pattern: '[0-9]*',
                                        min: getMinValue(),
                                        max: getMaxValue()
                                    }}
                                />
                                <FormHelperText error>{error.value}</FormHelperText>
                            </Grid>

                        ) : (
                            <Grid item>
                                <FormControl
                                    component="fieldset"
                                    sx={{ m: 1 }}
                                >
                                    <FormLabel id="light-state-value-select">Value</FormLabel>
                                    <RadioGroup
                                        aria-labelledby="light-state-value-select"
                                        value={value}
                                        name="light-state-value-select"
                                        onChange={e => setAttributeValue(e.target.value)}
                                    >
                                        <FormControlLabel value="ON" control={<Radio />} label="On" />
                                        <FormControlLabel value="OFF" control={<Radio />} label="Off" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        )}

                        {/* Unit */}
                        {attribute !== 'lightState' && (
                            <Grid item>
                                <FormControl
                                    component="fieldset"
                                    sx={{ m: 1 }}
                                >
                                    <FormLabel id="unit-select">Unit</FormLabel>
                                    <RadioGroup
                                        aria-labelledby="unit-select"
                                        value={unit}
                                        name="unit-select"
                                        onChange={e => setAttributeUnit(e.target.value)}
                                    >
                                        {attribute === 'temp' && (<FormControlLabel value="C" control={<Radio />} label="C" />)}
                                        {attribute === 'temp' && (<FormControlLabel value="F" control={<Radio />} label="F" />)}
                                        {attribute === 'humidity' && (<FormControlLabel value="%" control={<Radio />} label="%" />)}
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        )}

                    </Grid>

                )}
            </Grid>
            <Grid item xs={12} container justifyContent="space-between">
                <Grid item sm={6} md={4} lg={3}>
                    <Button
                        color="inherit"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                    >
                        Back
                    </Button>


                </Grid>
                <Grid item sm={6} md={4} lg={3}>
                    {activeStep < 2 ? (
                        <Button onClick={handleNext}>
                            Next
                        </Button>
                    ) : (
                        <Button
                            sx={{ m: 1 }}
                            variant="contained"
                            type="submit"
                            onClick={handleSubmit}
                            disabled={!validate()}
                        >
                            Add Alarm
                        </Button>
                    )}
                </Grid>
            </Grid>

        </Box>
    );

}


export default CreateAlarm