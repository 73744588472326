import { useAuthenticator, Heading } from '@aws-amplify/ui-react';
import React, { useState, useEffect } from 'react';
import { API } from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';
import { listSensors } from '../graphql/queries';
import { useNavigate } from 'react-router-dom';
import { useOutletContext } from "react-router-dom";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Grid, LinearProgress } from '@mui/material'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';


function ListSensors(props) {

  const [sensors, setSensors] = useState([]);
  const [setPageDrawerOptions] = useOutletContext();
  const [loading, setLoading] = useState(false)

  const navigate = useNavigate();

  useEffect(() => {
    fetchSensors();
    setPageDrawerOptions([{ onClick: () => navigate('/sensors/add'), icon: <AddCircleOutlineIcon />, label: "Add Sensor" }])
  }, []);

  useEffect(() => () => setPageDrawerOptions([]), []);

  const { route } = useAuthenticator((context) => [context.route]);
  if (route !== 'authenticated') return <Heading level={1}>Loading...</Heading>;

  async function fetchSensors() {
    setLoading(true)
    const apiData = await API.graphql({ query: listSensors });
    setSensors(apiData.data.listSensors.items);
    setLoading(false)
  }
  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <Typography variant='h4'>Your Sensors</Typography>
        {sensors?.length > 0 && <Typography variant='subtitle1'>Click a row to view sensor data and configuration</Typography>}
      </Grid>
      <Grid item>
        {loading ? <LinearProgress /> : sensors?.length > 0 ? (
          <TableContainer component={Paper}>

            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell align="right">Name</TableCell>
                  <TableCell align="right">Type</TableCell>
                  <TableCell align="right">Description</TableCell>
                  <TableCell align="right">Records Temp</TableCell>
                  <TableCell align="right">Records Humidity</TableCell>
                  <TableCell align="right">Records Light State</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sensors.map((sensor) => (
                  <TableRow
                    key={sensor.id}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                      "&:hover": {
                        boxShadow: 8,
                        cursor: "pointer"
                      },
                    }}
                    onClick={() => navigate(sensor.id)}
                  >
                    <TableCell component="th" scope="row">
                      {sensor.id}
                    </TableCell>
                    <TableCell align="right">{sensor.name}</TableCell>
                    <TableCell align="right">{sensor.type}</TableCell>
                    <TableCell align="right">{sensor.description}</TableCell>
                    <TableCell align="right">{sensor.hasTemp.toString()}</TableCell>
                    <TableCell align="right">{sensor.hasHumidity.toString()}</TableCell>
                    <TableCell align="right">{sensor.hasLightState.toString()}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <div>
            <Typography variant='h6'>No Sensors to view</Typography>
            <Typography variant='subtitle2'>Click "Add Sensor" in the left menu to get started</Typography>
          </div>
        )

        }
      </Grid>

    </Grid>
  );
}

export default ListSensors;