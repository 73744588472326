/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      sub
      region
      userPoolId
      email
      firstName
      lastName
      owner
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      sub
      region
      userPoolId
      email
      firstName
      lastName
      owner
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      sub
      region
      userPoolId
      email
      firstName
      lastName
      owner
    }
  }
`;
export const createPoint = /* GraphQL */ `
  mutation CreatePoint(
    $input: CreatePointInput!
    $condition: ModelPointConditionInput
  ) {
    createPoint(input: $input, condition: $condition) {
      id
      time
      createdAt
      temp
      tempUnit
      humidity
      humidityUnit
      lightState
      sensorId
      sensor {
        id
        type
        name
        description
        hasTemp
        hasLightState
        hasHumidity
        macAddress
        mostRecentPoint {
          id
          time
          createdAt
          temp
          tempUnit
          humidity
          humidityUnit
          lightState
          sensorId
          owner
        }
        triggers {
          nextToken
        }
        owner
      }
      owner
    }
  }
`;
export const updatePoint = /* GraphQL */ `
  mutation UpdatePoint(
    $input: UpdatePointInput!
    $condition: ModelPointConditionInput
  ) {
    updatePoint(input: $input, condition: $condition) {
      id
      time
      createdAt
      temp
      tempUnit
      humidity
      humidityUnit
      lightState
      sensorId
      sensor {
        id
        type
        name
        description
        hasTemp
        hasLightState
        hasHumidity
        macAddress
        mostRecentPoint {
          id
          time
          createdAt
          temp
          tempUnit
          humidity
          humidityUnit
          lightState
          sensorId
          owner
        }
        triggers {
          nextToken
        }
        owner
      }
      owner
    }
  }
`;
export const deletePoint = /* GraphQL */ `
  mutation DeletePoint(
    $input: DeletePointInput!
    $condition: ModelPointConditionInput
  ) {
    deletePoint(input: $input, condition: $condition) {
      id
      time
      createdAt
      temp
      tempUnit
      humidity
      humidityUnit
      lightState
      sensorId
      sensor {
        id
        type
        name
        description
        hasTemp
        hasLightState
        hasHumidity
        macAddress
        mostRecentPoint {
          id
          time
          createdAt
          temp
          tempUnit
          humidity
          humidityUnit
          lightState
          sensorId
          owner
        }
        triggers {
          nextToken
        }
        owner
      }
      owner
    }
  }
`;
export const createSensor = /* GraphQL */ `
  mutation CreateSensor(
    $input: CreateSensorInput!
    $condition: ModelSensorConditionInput
  ) {
    createSensor(input: $input, condition: $condition) {
      id
      type
      name
      description
      hasTemp
      hasLightState
      hasHumidity
      macAddress
      mostRecentPoint {
        id
        time
        createdAt
        temp
        tempUnit
        humidity
        humidityUnit
        lightState
        sensorId
        sensor {
          id
          type
          name
          description
          hasTemp
          hasLightState
          hasHumidity
          macAddress
          owner
        }
        owner
      }
      triggers {
        items {
          id
          sensorId
          attribute
          unit
          isRange
          rangeStart
          rangeEnd
          stringValue
          numValue
          timeStart
          timeEnd
          comparitor
          owner
        }
        nextToken
      }
      owner
    }
  }
`;
export const updateSensor = /* GraphQL */ `
  mutation UpdateSensor(
    $input: UpdateSensorInput!
    $condition: ModelSensorConditionInput
  ) {
    updateSensor(input: $input, condition: $condition) {
      id
      type
      name
      description
      hasTemp
      hasLightState
      hasHumidity
      macAddress
      mostRecentPoint {
        id
        time
        createdAt
        temp
        tempUnit
        humidity
        humidityUnit
        lightState
        sensorId
        sensor {
          id
          type
          name
          description
          hasTemp
          hasLightState
          hasHumidity
          macAddress
          owner
        }
        owner
      }
      triggers {
        items {
          id
          sensorId
          attribute
          unit
          isRange
          rangeStart
          rangeEnd
          stringValue
          numValue
          timeStart
          timeEnd
          comparitor
          owner
        }
        nextToken
      }
      owner
    }
  }
`;
export const deleteSensor = /* GraphQL */ `
  mutation DeleteSensor(
    $input: DeleteSensorInput!
    $condition: ModelSensorConditionInput
  ) {
    deleteSensor(input: $input, condition: $condition) {
      id
      type
      name
      description
      hasTemp
      hasLightState
      hasHumidity
      macAddress
      mostRecentPoint {
        id
        time
        createdAt
        temp
        tempUnit
        humidity
        humidityUnit
        lightState
        sensorId
        sensor {
          id
          type
          name
          description
          hasTemp
          hasLightState
          hasHumidity
          macAddress
          owner
        }
        owner
      }
      triggers {
        items {
          id
          sensorId
          attribute
          unit
          isRange
          rangeStart
          rangeEnd
          stringValue
          numValue
          timeStart
          timeEnd
          comparitor
          owner
        }
        nextToken
      }
      owner
    }
  }
`;
export const createTrigger = /* GraphQL */ `
  mutation CreateTrigger(
    $input: CreateTriggerInput!
    $condition: ModelTriggerConditionInput
  ) {
    createTrigger(input: $input, condition: $condition) {
      id
      sensorId
      sensor {
        id
        type
        name
        description
        hasTemp
        hasLightState
        hasHumidity
        macAddress
        mostRecentPoint {
          id
          time
          createdAt
          temp
          tempUnit
          humidity
          humidityUnit
          lightState
          sensorId
          owner
        }
        triggers {
          nextToken
        }
        owner
      }
      attribute
      unit
      isRange
      rangeStart
      rangeEnd
      stringValue
      numValue
      timeStart
      timeEnd
      comparitor
      actions {
        items {
          id
          triggerId
          actionType
          alarmId
          priority
          attribute
          alarmTitle
          owner
        }
        nextToken
      }
      owner
    }
  }
`;
export const updateTrigger = /* GraphQL */ `
  mutation UpdateTrigger(
    $input: UpdateTriggerInput!
    $condition: ModelTriggerConditionInput
  ) {
    updateTrigger(input: $input, condition: $condition) {
      id
      sensorId
      sensor {
        id
        type
        name
        description
        hasTemp
        hasLightState
        hasHumidity
        macAddress
        mostRecentPoint {
          id
          time
          createdAt
          temp
          tempUnit
          humidity
          humidityUnit
          lightState
          sensorId
          owner
        }
        triggers {
          nextToken
        }
        owner
      }
      attribute
      unit
      isRange
      rangeStart
      rangeEnd
      stringValue
      numValue
      timeStart
      timeEnd
      comparitor
      actions {
        items {
          id
          triggerId
          actionType
          alarmId
          priority
          attribute
          alarmTitle
          owner
        }
        nextToken
      }
      owner
    }
  }
`;
export const deleteTrigger = /* GraphQL */ `
  mutation DeleteTrigger(
    $input: DeleteTriggerInput!
    $condition: ModelTriggerConditionInput
  ) {
    deleteTrigger(input: $input, condition: $condition) {
      id
      sensorId
      sensor {
        id
        type
        name
        description
        hasTemp
        hasLightState
        hasHumidity
        macAddress
        mostRecentPoint {
          id
          time
          createdAt
          temp
          tempUnit
          humidity
          humidityUnit
          lightState
          sensorId
          owner
        }
        triggers {
          nextToken
        }
        owner
      }
      attribute
      unit
      isRange
      rangeStart
      rangeEnd
      stringValue
      numValue
      timeStart
      timeEnd
      comparitor
      actions {
        items {
          id
          triggerId
          actionType
          alarmId
          priority
          attribute
          alarmTitle
          owner
        }
        nextToken
      }
      owner
    }
  }
`;
export const createAction = /* GraphQL */ `
  mutation CreateAction(
    $input: CreateActionInput!
    $condition: ModelActionConditionInput
  ) {
    createAction(input: $input, condition: $condition) {
      id
      triggerId
      trigger {
        id
        sensorId
        sensor {
          id
          type
          name
          description
          hasTemp
          hasLightState
          hasHumidity
          macAddress
          owner
        }
        attribute
        unit
        isRange
        rangeStart
        rangeEnd
        stringValue
        numValue
        timeStart
        timeEnd
        comparitor
        actions {
          nextToken
        }
        owner
      }
      actionType
      alarmId
      alarm {
        id
        actionId
        action {
          id
          triggerId
          actionType
          alarmId
          priority
          attribute
          alarmTitle
          owner
        }
        priority
        status
        acknowledgedById
        acknowledgedAt
        sensorId
        attribute
        recipientIds
        title
        owner
      }
      priority
      attribute
      alarmTitle
      owner
    }
  }
`;
export const updateAction = /* GraphQL */ `
  mutation UpdateAction(
    $input: UpdateActionInput!
    $condition: ModelActionConditionInput
  ) {
    updateAction(input: $input, condition: $condition) {
      id
      triggerId
      trigger {
        id
        sensorId
        sensor {
          id
          type
          name
          description
          hasTemp
          hasLightState
          hasHumidity
          macAddress
          owner
        }
        attribute
        unit
        isRange
        rangeStart
        rangeEnd
        stringValue
        numValue
        timeStart
        timeEnd
        comparitor
        actions {
          nextToken
        }
        owner
      }
      actionType
      alarmId
      alarm {
        id
        actionId
        action {
          id
          triggerId
          actionType
          alarmId
          priority
          attribute
          alarmTitle
          owner
        }
        priority
        status
        acknowledgedById
        acknowledgedAt
        sensorId
        attribute
        recipientIds
        title
        owner
      }
      priority
      attribute
      alarmTitle
      owner
    }
  }
`;
export const deleteAction = /* GraphQL */ `
  mutation DeleteAction(
    $input: DeleteActionInput!
    $condition: ModelActionConditionInput
  ) {
    deleteAction(input: $input, condition: $condition) {
      id
      triggerId
      trigger {
        id
        sensorId
        sensor {
          id
          type
          name
          description
          hasTemp
          hasLightState
          hasHumidity
          macAddress
          owner
        }
        attribute
        unit
        isRange
        rangeStart
        rangeEnd
        stringValue
        numValue
        timeStart
        timeEnd
        comparitor
        actions {
          nextToken
        }
        owner
      }
      actionType
      alarmId
      alarm {
        id
        actionId
        action {
          id
          triggerId
          actionType
          alarmId
          priority
          attribute
          alarmTitle
          owner
        }
        priority
        status
        acknowledgedById
        acknowledgedAt
        sensorId
        attribute
        recipientIds
        title
        owner
      }
      priority
      attribute
      alarmTitle
      owner
    }
  }
`;
export const createAlarm = /* GraphQL */ `
  mutation CreateAlarm(
    $input: CreateAlarmInput!
    $condition: ModelAlarmConditionInput
  ) {
    createAlarm(input: $input, condition: $condition) {
      id
      actionId
      action {
        id
        triggerId
        trigger {
          id
          sensorId
          attribute
          unit
          isRange
          rangeStart
          rangeEnd
          stringValue
          numValue
          timeStart
          timeEnd
          comparitor
          owner
        }
        actionType
        alarmId
        alarm {
          id
          actionId
          priority
          status
          acknowledgedById
          acknowledgedAt
          sensorId
          attribute
          recipientIds
          title
          owner
        }
        priority
        attribute
        alarmTitle
        owner
      }
      priority
      status
      acknowledgedById
      acknowledgedAt
      sensorId
      attribute
      recipientIds
      title
      owner
    }
  }
`;
export const updateAlarm = /* GraphQL */ `
  mutation UpdateAlarm(
    $input: UpdateAlarmInput!
    $condition: ModelAlarmConditionInput
  ) {
    updateAlarm(input: $input, condition: $condition) {
      id
      actionId
      action {
        id
        triggerId
        trigger {
          id
          sensorId
          attribute
          unit
          isRange
          rangeStart
          rangeEnd
          stringValue
          numValue
          timeStart
          timeEnd
          comparitor
          owner
        }
        actionType
        alarmId
        alarm {
          id
          actionId
          priority
          status
          acknowledgedById
          acknowledgedAt
          sensorId
          attribute
          recipientIds
          title
          owner
        }
        priority
        attribute
        alarmTitle
        owner
      }
      priority
      status
      acknowledgedById
      acknowledgedAt
      sensorId
      attribute
      recipientIds
      title
      owner
    }
  }
`;
export const deleteAlarm = /* GraphQL */ `
  mutation DeleteAlarm(
    $input: DeleteAlarmInput!
    $condition: ModelAlarmConditionInput
  ) {
    deleteAlarm(input: $input, condition: $condition) {
      id
      actionId
      action {
        id
        triggerId
        trigger {
          id
          sensorId
          attribute
          unit
          isRange
          rangeStart
          rangeEnd
          stringValue
          numValue
          timeStart
          timeEnd
          comparitor
          owner
        }
        actionType
        alarmId
        alarm {
          id
          actionId
          priority
          status
          acknowledgedById
          acknowledgedAt
          sensorId
          attribute
          recipientIds
          title
          owner
        }
        priority
        attribute
        alarmTitle
        owner
      }
      priority
      status
      acknowledgedById
      acknowledgedAt
      sensorId
      attribute
      recipientIds
      title
      owner
    }
  }
`;
