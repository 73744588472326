/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($sub: ID!) {
    getUser(sub: $sub) {
      sub
      region
      userPoolId
      email
      firstName
      lastName
      owner
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $sub: ID
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUsers(
      sub: $sub
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        sub
        region
        userPoolId
        email
        firstName
        lastName
        owner
      }
      nextToken
    }
  }
`;
export const getPoint = /* GraphQL */ `
  query GetPoint($id: ID!) {
    getPoint(id: $id) {
      id
      time
      createdAt
      temp
      tempUnit
      humidity
      humidityUnit
      lightState
      sensorId
      sensor {
        id
        type
        name
        description
        hasTemp
        hasLightState
        hasHumidity
        macAddress
        mostRecentPoint {
          id
          time
          createdAt
          temp
          tempUnit
          humidity
          humidityUnit
          lightState
          sensorId
          owner
        }
        triggers {
          nextToken
        }
        owner
      }
      owner
    }
  }
`;
export const listPoints = /* GraphQL */ `
  query ListPoints(
    $filter: ModelPointFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPoints(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        time
        createdAt
        temp
        tempUnit
        humidity
        humidityUnit
        lightState
        sensorId
        sensor {
          id
          type
          name
          description
          hasTemp
          hasLightState
          hasHumidity
          macAddress
          owner
        }
        owner
      }
      nextToken
    }
  }
`;
export const getSensor = /* GraphQL */ `
  query GetSensor($id: ID!) {
    getSensor(id: $id) {
      id
      type
      name
      description
      hasTemp
      hasLightState
      hasHumidity
      macAddress
      mostRecentPoint {
        id
        time
        createdAt
        temp
        tempUnit
        humidity
        humidityUnit
        lightState
        sensorId
        sensor {
          id
          type
          name
          description
          hasTemp
          hasLightState
          hasHumidity
          macAddress
          owner
        }
        owner
      }
      triggers {
        items {
          id
          sensorId
          attribute
          unit
          isRange
          rangeStart
          rangeEnd
          stringValue
          numValue
          timeStart
          timeEnd
          comparitor
          owner
        }
        nextToken
      }
      owner
    }
  }
`;
export const listSensors = /* GraphQL */ `
  query ListSensors(
    $filter: ModelSensorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSensors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        name
        description
        hasTemp
        hasLightState
        hasHumidity
        macAddress
        mostRecentPoint {
          id
          time
          createdAt
          temp
          tempUnit
          humidity
          humidityUnit
          lightState
          sensorId
          owner
        }
        triggers {
          nextToken
        }
        owner
      }
      nextToken
    }
  }
`;
export const getTrigger = /* GraphQL */ `
  query GetTrigger($id: ID!) {
    getTrigger(id: $id) {
      id
      sensorId
      sensor {
        id
        type
        name
        description
        hasTemp
        hasLightState
        hasHumidity
        macAddress
        mostRecentPoint {
          id
          time
          createdAt
          temp
          tempUnit
          humidity
          humidityUnit
          lightState
          sensorId
          owner
        }
        triggers {
          nextToken
        }
        owner
      }
      attribute
      unit
      isRange
      rangeStart
      rangeEnd
      stringValue
      numValue
      timeStart
      timeEnd
      comparitor
      actions {
        items {
          id
          triggerId
          actionType
          alarmId
          priority
          attribute
          alarmTitle
          owner
        }
        nextToken
      }
      owner
    }
  }
`;
export const listTriggers = /* GraphQL */ `
  query ListTriggers(
    $filter: ModelTriggerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTriggers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        sensorId
        sensor {
          id
          type
          name
          description
          hasTemp
          hasLightState
          hasHumidity
          macAddress
          owner
        }
        attribute
        unit
        isRange
        rangeStart
        rangeEnd
        stringValue
        numValue
        timeStart
        timeEnd
        comparitor
        actions {
          nextToken
        }
        owner
      }
      nextToken
    }
  }
`;
export const getAction = /* GraphQL */ `
  query GetAction($id: ID!) {
    getAction(id: $id) {
      id
      triggerId
      trigger {
        id
        sensorId
        sensor {
          id
          type
          name
          description
          hasTemp
          hasLightState
          hasHumidity
          macAddress
          owner
        }
        attribute
        unit
        isRange
        rangeStart
        rangeEnd
        stringValue
        numValue
        timeStart
        timeEnd
        comparitor
        actions {
          nextToken
        }
        owner
      }
      actionType
      alarmId
      alarm {
        id
        actionId
        action {
          id
          triggerId
          actionType
          alarmId
          priority
          attribute
          alarmTitle
          owner
        }
        priority
        status
        acknowledgedById
        acknowledgedAt
        sensorId
        attribute
        recipientIds
        title
        owner
      }
      priority
      attribute
      alarmTitle
      owner
    }
  }
`;
export const listActions = /* GraphQL */ `
  query ListActions(
    $filter: ModelActionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listActions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        triggerId
        trigger {
          id
          sensorId
          attribute
          unit
          isRange
          rangeStart
          rangeEnd
          stringValue
          numValue
          timeStart
          timeEnd
          comparitor
          owner
        }
        actionType
        alarmId
        alarm {
          id
          actionId
          priority
          status
          acknowledgedById
          acknowledgedAt
          sensorId
          attribute
          recipientIds
          title
          owner
        }
        priority
        attribute
        alarmTitle
        owner
      }
      nextToken
    }
  }
`;
export const getAlarm = /* GraphQL */ `
  query GetAlarm($id: ID!) {
    getAlarm(id: $id) {
      id
      actionId
      action {
        id
        triggerId
        trigger {
          id
          sensorId
          attribute
          unit
          isRange
          rangeStart
          rangeEnd
          stringValue
          numValue
          timeStart
          timeEnd
          comparitor
          owner
        }
        actionType
        alarmId
        alarm {
          id
          actionId
          priority
          status
          acknowledgedById
          acknowledgedAt
          sensorId
          attribute
          recipientIds
          title
          owner
        }
        priority
        attribute
        alarmTitle
        owner
      }
      priority
      status
      acknowledgedById
      acknowledgedAt
      sensorId
      attribute
      recipientIds
      title
      owner
    }
  }
`;
export const listAlarms = /* GraphQL */ `
  query ListAlarms(
    $filter: ModelAlarmFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAlarms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        actionId
        action {
          id
          triggerId
          actionType
          alarmId
          priority
          attribute
          alarmTitle
          owner
        }
        priority
        status
        acknowledgedById
        acknowledgedAt
        sensorId
        attribute
        recipientIds
        title
        owner
      }
      nextToken
    }
  }
`;
export const pointsBySensorId = /* GraphQL */ `
  query PointsBySensorId(
    $sensorId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPointFilterInput
    $limit: Int
    $nextToken: String
  ) {
    pointsBySensorId(
      sensorId: $sensorId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        time
        createdAt
        temp
        tempUnit
        humidity
        humidityUnit
        lightState
        sensorId
        sensor {
          id
          type
          name
          description
          hasTemp
          hasLightState
          hasHumidity
          macAddress
          owner
        }
        owner
      }
      nextToken
    }
  }
`;
