import React from 'react'
import { Card, CardContent, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const AttributeIndicators = props => {
    const { indicators, timeSinceLatestPoint } = props;

    // STYLE
    const theme = useTheme()
    const styles = {
        card: {
            transition: "0.3s",
            boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
            "&:hover": {
                boxShadow: "0 24px 70px -12.125px rgba(0,0,0,0.3)"
            }
        },
        cardContent: {
            textAlign: 'center',
            padding: theme.spacing(3),
        },
        cardTitle: { fontSize: 14 },
        cardSubText: { mb: 1.5 },
    }

    return (
        <div>
            <Typography variant='h5'></Typography>
            <Typography sx={styles.cardTitle} color="text.secondary" gutterBottom>{indicators.title} </Typography>
            {indicators.noData ? (
                <Typography sx={styles.cardSubText} color="text.secondary">No Data</Typography>
            ) : (
                <span>
                    <Typography variant="h5" component="div">{indicators.latestValue}</Typography>
                    <Typography sx={styles.cardSubText} color="text.secondary">{timeSinceLatestPoint}</Typography>
                </span>
            )}
        </div>
    )
}

export default AttributeIndicators