import React, { useState } from 'react';
import { createSensor as createSensorMutation } from '../../graphql/mutations';
import { API } from 'aws-amplify';
import { useTheme } from '@mui/material/styles';
import {
  Grid,
  Box,
  TextField,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
  FormGroup,
  Checkbox,
  FormHelperText,
  Button,
  Typography
} from '@mui/material';

const CreateSensor = () => {
  const [name, setName] = useState('');
  const [type, setType] = useState('mock');
  const [macAddress, setMacAddress] = useState('');
  const [description, setDescription] = useState('');
  const [hasTemp, setHasTemp] = useState(true);
  const [hasHumidity, setHasHumidity] = useState(true);
  const [hasLightState, setHasLightState] = useState(true);
  const [loading, setLoading] = useState(false)

  const theme = useTheme()

  const validate = () => {
    if (loading) {
      return false
    }
    if (!name || !type || !(hasTemp || hasHumidity || hasLightState)) {
      return false;
    }
    if (type !== 'mock' && !macAddress) {
      return false
    }
    return true;
  }

  const resetState = () => {
    setName('')
    setMacAddress('')
    setDescription('')
    setHasTemp(true)
    setHasHumidity(true)
    setHasLightState(true)
    setLoading(false)
  }
  const handleSubmit = async () => {
    if (validate()) {
      setLoading(true)
      try {
        await API.graphql({
          query: createSensorMutation,
          variables: {
            input: {
              id: macAddress ? macAddress + Date.now() : Date.now(),
              name,
              type,
              description,
              macAddress,
              hasTemp,
              hasLightState,
              hasHumidity
            }
          }
        });
        resetState()
      } catch (e) {
        console.error("Error submitting", e);
      }
    }
  }

  const handleChangeType = e => {
    if (e.target.value !== 'mock') {
      setMacAddress('')
    }
    setType(e.target.value)
  }

  return (
    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1, width: '25ch' },
        width: '100%'
      }}
      // onSubmit={handleSubmit}
      autoComplete="off"
    >
      <Grid container direction="column" spacing={1}>

        <Grid item>
          <Typography variant='h4' sx={{ marginBottom: theme.spacing(2) }}>Add a new Sensor</Typography>
        </Grid>
        {/* Sensor Type */}
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <FormControl
            component="fieldset"
            sx={{ m: 1 }}
          >
            <FormLabel id="type-select">Type</FormLabel>
            <RadioGroup
              aria-labelledby="type-select"
              value={type}
              name="type-select"
              onChange={handleChangeType}
            >
              <FormControlLabel value="mock" control={<Radio />} label="Mock" />
              <FormControlLabel value="pseudo" control={<Radio />} label="Software / Pseudo" />
              <FormControlLabel value="physical" control={<Radio />} label="Physical" />
            </RadioGroup>
            <FormHelperText>A mock sensor will auto configure and post a data point every 5 minutes</FormHelperText>
          </FormControl>
        </Grid>



        <Grid item xs={12} sm={6} md={4} lg={3}>
          <FormControl
            required
            error={!(hasTemp || hasHumidity || hasLightState)}
            component="fieldset"
            sx={{ m: 1 }}
            variant="standard"
          >
            <FormLabel component="legend">Date Type(s) Recorded</FormLabel>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox checked={hasTemp} onChange={e => setHasTemp(e.target.checked)} name="temp" />
                }
                label="Temperature"
              />
              <FormControlLabel
                control={
                  <Checkbox checked={hasHumidity} onChange={e => setHasHumidity(e.target.checked)} name="humidity" />
                }
                label="Humidity"
              />
              <FormControlLabel
                control={
                  <Checkbox checked={hasLightState} onChange={e => setHasLightState(e.target.checked)} name="lightState" />
                }
                label="Light State"
              />
            </FormGroup>
            {!(hasTemp || hasHumidity || hasLightState) && (
              <FormHelperText>Sensor must have at least one attribute</FormHelperText>
            )}
          </FormControl>
        </Grid>
        {/* AWS Thing ARN. Do not show for mock sensors */}
        {type !== 'mock' && (
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextField
              required
              value={macAddress}
              onChange={e => setMacAddress(e.target.value)}
              label="MAC Address"
              variant="outlined"
            />
          </Grid>
        )}
        {/* Name */}
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <TextField
            required
            value={name}
            onChange={e => setName(e.target.value)}
            label="Name"
            variant="outlined"
          />
        </Grid>
        {/* Desc */}
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <TextField
            multiline
            rows={2}
            value={description}
            onChange={e => setDescription(e.target.value)}
            label="Description"
          />
        </Grid>

      </Grid>
      <Grid item xs={12} container >
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Button
            sx={{ m: 1 }}
            variant="contained"
            type="submit"
            onClick={handleSubmit}
            disabled={!validate()}>
            Submit
          </Button>
        </Grid>
      </Grid>
    </Box>
  );

}


export default CreateSensor