import { useLocation, Navigate } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';

/*
    If you're logged in and refresh the browser, the route may temporarily be set to idle or setup while it loads. 
    That will cause a redirection back to the /login page, which in turn will redirect back to the protected route. 
    The user will not notice anything, and the protected route will be displayed. However, 
    if you like to have more control of this process you can use Auth.currentAuthenticatedUser() instead.
 */
export function RequireAuth(props) {
    const { children } = props
    const location = useLocation();
    const { route } = useAuthenticator((context) => [context.route]);
    if (route !== 'authenticated') {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }
    return children;
}