import React, { useState, useEffect } from 'react';
import { API } from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';
import { pointsBySensorId, getSensor } from '../../graphql/queries';
import { LinearProgress, Typography, Grid, CircularProgress } from '@mui/material'
import { useParams, useLocation } from "react-router-dom";
import { sortPoints } from './utils';
import Attributes from './Attributes'
import moment from 'moment'
import { useOutletContext, useNavigate } from "react-router-dom";
import ThermostatIcon from '@mui/icons-material/Thermostat';
import OpacityIcon from '@mui/icons-material/Opacity';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import { IconButton } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import RefreshIcon from '@mui/icons-material/Refresh';

function ListPoints() {
    const [points, setPoints] = useState();
    const [sensor, setSensor] = useState()
    const [refresh, setRefresh] = useState(0)
    const [refreshing, setRefreshing] = useState(false)
    const [timeRange, setTimeRange] = useState({ number: 24, unit: 'hours' })
    const [loading, setLoading] = useState(true)
    const { sensorId } = useParams();
    const [selectedAttribute, setSelectedAttribute] = useState('temp')


    const [setPageDrawerOptions, setPathname] = useOutletContext();

    const { pathname } = useLocation()
    const navigate = useNavigate()

    // Get sensor 
    useEffect(() => {
        async function fetchSensor() {
            const apiData = await API.graphql({ query: getSensor, variables: { id: sensorId } });
            setSensor(apiData.data.getSensor);
        }
        fetchSensor()
    }, [sensorId]);

    // Initialize points
    useEffect(() => {
        async function fetchPoints() {
            const timeRangeMoment = moment()
            timeRangeMoment.subtract(timeRange.number, timeRange.unit)
            const filter = {
                time: { ge: timeRangeMoment.unix() * 1000 }
            }
            const apiData = await API.graphql({ query: pointsBySensorId, variables: { sensorId, filter, limit: 10000000 } });
            setPoints(apiData.data.pointsBySensorId.items);
            setRefreshing(false)
        }
        fetchPoints();
    }, [sensorId, timeRange, refresh]);

    // Initialize layout options
    useEffect(() => {
        const options = [];
        if (sensor?.hasTemp) {
            setSelectedAttribute('temp')
        } else if (sensor?.hasHumidity) {
            setSelectedAttribute('humidity')
        } else {
            setSelectedAttribute('lightState')
        }

        // Use sensor details to initialize better breadcrumbs
        if (sensor?.name && pathname.includes(sensorId)) {
            setPathname(pathname.replace(sensorId, sensor.name))
        }
        // Add action buttons to the side drawer
        if (sensor) {
            if (sensor.hasTemp) options.push({ onClick: () => setSelectedAttribute('temp'), icon: <ThermostatIcon />, label: "Temperature" })
            if (sensor.hasHumidity) options.push({ onClick: () => setSelectedAttribute('humidity'), icon: <OpacityIcon />, label: "Humidity" })
            if (sensor.hasLightState) options.push({ onClick: () => setSelectedAttribute('lightState'), icon: <LightbulbIcon />, label: "Light" })
            options.push({ onClick: () => navigate('alarms/create'), icon: <NotificationsIcon />, label: "Create Alarm" })
        }
        setPageDrawerOptions(options)

    }, [sensor]);

    useEffect(() => () => {
        setPageDrawerOptions([]);
        setPathname()
    }, []);

    useEffect(() => {
        if (sensor && points && loading) {
            setLoading(false)
        }
    }, [sensor, points, loading]);

    const handleRefresh = () => {
        setRefresh(refresh + 1)
        setRefreshing(true);
    }

    if (loading) return <LinearProgress />

    return (
        <div>
            <div>
                <Typography variant='h4'>Sensor: {sensor?.name}</Typography>
                {sensor.type === 'mock' && <Typography variant='subheader' >This is a mock sensor. A new data point will be posted every 5 minutes.</Typography>}
            </div>
            <Grid container justifyContent="flex-end">
                <div style={{ height: 52, width: 52 }}>
                    {refreshing ? (
                        <CircularProgress />
                    ) : (
                        <IconButton aria-label="refresh" size="large" onClick={handleRefresh}>
                            <RefreshIcon fontSize="inherit" />
                        </IconButton>
                    )
                    }
                </div>
            </Grid >
            <Attributes
                points={sortPoints(points)}
                sensor={sensor}
                timeRange={timeRange}
                selectedAttribute={selectedAttribute}
                refresh={refresh}
            />
        </div >
    );
}

export default ListPoints;