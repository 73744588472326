import { useAuthenticator } from '@aws-amplify/ui-react';
import { Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Dashboard = () => {
    const { route } = useAuthenticator((context) => [context.route]);

    const navigate = useNavigate();
    const authenticated = route === 'authenticated';

    return (
        <Grid container justifyContent="space-between">
            <Grid item>
                <Typography variant="h5">Public Landing Page</Typography>
            </Grid>
            <Grid item>
                {!authenticated && (

                    <Typography
                        sx={{
                            "&:hover": {
                                textDecoration: 'underline',
                                cursor: "pointer",
                                color: 'inherit'
                            },
                        }}
                        onClick={() => navigate('/login')}
                    >
                        Login / Sign up
                    </Typography>
                )}
            </Grid>
        </Grid>
    );
}

export default Dashboard