import { useEffect } from "react";
import { Authenticator, useAuthenticator, View } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { useNavigate, useLocation } from 'react-router';

export function Login() {

    // We destructure useAuthenticator and receive the route. The route can be used to tell if a route is authenticated or not. 
    // The route can also be set to signIn,signOut, and even idle or setup depending on where the user is during the authentication flow.
    // You may have noticed that we passed into the useAuthenticator a context callback function: (context) => [context.route] 
    // This is very important as it will help prevent unneccessary re-renders of the application. Excluding it may also cause errors!
    // https://ui.docs.amplify.aws/react/guides/auth-protected
    const { route } = useAuthenticator((context) => [context.route]);

    const location = useLocation();
    const navigate = useNavigate();
    let from = location.state?.from?.pathname || '/dashboard';
    useEffect(() => {
        if (route === 'authenticated') {
            navigate(from, { replace: true });
        }
    }, [route, navigate, from]);
    return (
        <View className="auth-wrapper">
            <Authenticator></Authenticator>
        </View>
    );
}
